<template>
    <div>
        <vx-card>
            <div slot="no-body" class="tabs-container px-6 pt-6">
                <div class="tab-text">
                    <div class="vx-row">
                        <div class="vx-col md:w-1/3 w-full mt-2">
                            <vs-input
                                v-validate="'required|min:3|max:255'"
                                :data-vv-as="$t('root.softwarePackage')"
                                data-vv-validate-on="blur"
                                name="packageName"
                                label="Tên gói"
                                v-model="packageOrg.packageName"
                                autocomplete="off"
                                class="w-full"/>
                            <span class="text-danger text-sm">{{
                                    errors.first("packageName")
                                }}</span>

                        </div>
                        <div class="vx-col md:w-2/3 w-full mt-2">
                            <label class="vs-input--label">{{ $t('root.usingAI') }}</label>
                            <multiselect
                                v-model="permissionFunctionsAI"
                                :options="permissionList"
                                :searchable="false"
                                :multiple="true"
                                :close-on-select="false"
                                :allow-empty="true"
                                :placeholder="$t('root.selectFunction')"
                                label="label"
                                track-by="value"
                            >
                            </multiselect>
                        </div>
                    </div>
                    <div class="vx-row">

                        <div class="vx-col md:w-1/4 w-full mt-2">
                            <label class="vs-input--label">{{ $t('root.storageTime') }}</label>
                            <v-select class="" v-model="selectedTimeStorages" :clearable="false"
                                      :options="timeStorages">
                                <span slot="no-options"> {{ $t('menuOrganization.noOption') }}</span>
                            </v-select>
                        </div>
                        <div class="vx-col md:w-1/4 w-full mt-2">
                            <vs-input
                                v-validate="'required|numeric'"
                                :data-vv-name="'s3_quota'"
                                data-vv-validate-on="blur"
                                :data-vv-as="$t('root.storedData')"
                                name="s3_quota"
                                :label="$t('root.storedData')"
                                v-model="packageOrg.s3_quota"
                                autocomplete="off"
                                class="w-full"/>
                            <span class="text-danger text-sm">{{
                                    errors.first("s3_quota")
                                }}</span>
                        </div>
                        <div class="vx-col md:w-1/4 w-full mt-2">
                            <label class="vs-input--label">{{ $t('root.resolution') }}</label>
                            <v-select class="" v-model="selectedProfile" :clearable="false"
                                      :options="profiles">
                                <span slot="no-options"> {{ $t('menuOrganization.noOption') }}</span>
                            </v-select>
                        </div>
                        <div class="vx-col md:w-1/4 w-full mt-2">
                            <vs-input
                                v-validate="'required|numeric'"
                                :data-vv-name="'amount'"
                                data-vv-validate-on="blur"
                                :data-vv-as="$t('root.amount')"
                                name="s3_quota"
                                :label="$t('root.amount')"
                                v-model="packageOrg.amount"
                                autocomplete="off"
                                class="w-full"/>
                            <span class="text-danger text-sm">{{
                                    errors.first("amount")
                                }}</span>
                        </div>
                    </div>
                    <div class="vx-row">
                        <div class="vx-col w-full">
                            <div class="mt-4 mb-4 flex flex-wrap items-center justify-end">
                                <vs-button class="ml-auto mt-2" @click="saveChanges"
                                           :disabled="!validateForm">{{
                                        `${isUpdate ?
                                            this.$t('button.update') : this.$t('button.create')}`
                                    }}
                                </vs-button>
                                <vs-button class="ml-4 mt-2" type="border" color="warning" @click="$router.go(-1)">
                                    {{ $t('button.back') }}
                                </vs-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </vx-card>
    </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import vSelect from 'vue-select'
import Multiselect from 'vue-multiselect';

export default {
    components: {
        vSelect,
        Multiselect
    },
    data() {
        return {
            isUpdate: false,
            id: null,
            packageOrgId: null,
            packageOrg: {
                packageName: null,
                // gia tien
                price: null,
                // thoi gian luu tru (ngay)
                timeStorage: 3,
                // so camera
                totalCamera: null,
                // so camera ai
                totalCameraAI: null,
                //Data luu tru
                s3_quota: null,
                //profile
                profile: 1,
                permissionFunctions: [],
                //Thoi han goi
                packageDuration: 3,
                amount: 0
            },
            profiles: [
                {value: 1, label: "360P"},
                {value: 2, label: "480P"},
                {value: 3, label: "640p"},
                {value: 8, label: "720p"},
                {value: 9, label: "1080p"},
                {value: 12, label: "HQ"}

            ],
            packageOrganization: [
                {value: 1, label: '1 ' + `${this.$t('root.day')}`},
                {value: 3, label: '3 ' + `${this.$t('root.day')}`},
                {value: 7, label: '7 ' + `${this.$t('root.day')}`},
                {value: 15, label: '15 ' + `${this.$t('root.day')}`},
                {value: 30, label: '30 ' + `${this.$t('root.day')}`}
            ],
            timeStorages: [
                {value: 1, label: '1 ' + `${this.$t('root.day')}`},
                {value: 3, label: '3 ' + `${this.$t('root.day')}`},
                {value: 7, label: '7 ' + `${this.$t('root.day')}`},
                {value: 15, label: '15 ' + `${this.$t('root.day')}`},
                {value: 30, label: '30 ' + `${this.$t('root.day')}`}
            ],
            permissionList: [],
            permissionFunctionsAI: [],
        };
    },
    computed: {
        selectedProfile: {
            get() {
                return {
                    label: this.getProfileString(this.packageOrg.profile),
                    value: this.packageOrg.profile
                }
            },
            set(obj) {
                this.packageOrg.profile = obj.value
            }
        },
        selectedPackageOrganization: {
            get() {
                return {
                    label: this.getPackageOrganizationString(this.packageOrg.packageDuration),
                    value: this.packageOrg.packageDuration
                }
            },
            set(obj) {
                this.packageOrg.packageDuration = obj.value
            }
        },
        selectedTimeStorages: {
            get() {
                return {
                    label: this.getTimeStoragesString(this.packageOrg.timeStorage),
                    value: this.packageOrg.timeStorage
                }
            },
            set(obj) {
                this.packageOrg.timeStorage = obj.value
            }
        },
        validateForm() {
            return !this.errors.any();
        },
    },
    async created() {
        await this.getPermissionAI();
        this.packageOrgId = this.$route.query.id;
        if (this.packageOrgId) {
            this.findById();
        }
    },
    methods: {
        findById() {
            this.$vs.loading();
            this.$crm.get('/camera-package/find-by-id/' + this.packageOrgId).then((response) => {
                this.$vs.loading.close();
                this.packageOrg = response.data;
                this.packageOrg.permissionFunctions.map(x => {
                    let packageOrgFunction = this.permissionList.find(p => p.value == x.id);
                    this.permissionFunctionsAI.push(packageOrgFunction);
                })
                this.isUpdate = true;
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        getPermissionAI() {
            return new Promise(resolve => {
                this.$vs.loading();
                this.$crm.post('/permission-function/find-all').then((response) => {
                    this.permissionList = response.data.map(x => {
                        let obj = {value: x.id, label: x.functionAlias};
                        return obj;
                    })
                    resolve(true);
                    this.$vs.loading.close();
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            })
        },
        getProfileString(id) {
            let profile = this.profiles.find(x => x.value === id);
            return (profile && profile.label) || this.$t('root.null');
        },
        getPackageOrganizationString(id) {
            let packageOrg = this.packageOrganization.find(x => x.value === id);
            return (packageOrg && packageOrg.label) || this.$t('root.null');
        },
        getTimeStoragesString(id) {
            let timeStore = this.timeStorages.find(x => x.value === id);
            return (timeStore && timeStore.label) || this.$t('root.null');
        },
        async saveChanges(evt) {
            evt.preventDefault();
            if (!this.validateForm) return;
            const result = await this.$validator.validateAll();
            if (!result) return;
            this.packageOrg.permissionFunctions = this.permissionFunctionsAI.map(x => x.value);
            let path = this.isUpdate ? ('/camera-package/update/' + this.packageOrgId) : '/camera-package/create';
            let params = this.packageOrg;
            this.$vs.loading();
            this.$crm.post(path, params).then(() => {
                this.$vs.loading.close();
                this.$vs.notify({
                    color: 'success',
                    text: `${this.isUpdate ? this.$t('root.update') : this.$t('root.create')} ${this.$t('root.successfulSoftware')}`,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                });
                this.$router.push('/admin/organization-package');
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
    }
};
</script>
